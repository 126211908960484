import React, { useState } from 'react'
import { Text } from '../../../components/Text'
import { List } from '../../../components/List'
import { Block } from '../../../components/Block'
import { Button } from '../../../components/Button/Button'
import { CitaPreviaModal } from '../../CitaPreviaModal'

export const TarifaUnica = () => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Text uppercase center>
        Tarifa única
      </Text>
      <Text weight="bold" size="h1" center marginBottom="size5">
        50€
      </Text>
      <List marginBottom="size7">
        <List.Item>Entrevista personal (toma de datos)</List.Item>
        <List.Item>
          Exploración y valoración de Fisioterapia & Osteopatía
        </List.Item>
        <List.Item>Tratamiento (recomendado o elegido)</List.Item>
        <List.Item>Recomendaciones de salud</List.Item>
      </List>
      <Block textAlign="center">
        <Button primary onClick={() => setOpen(true)}>
          Cita previa
        </Button>
      </Block>
      <CitaPreviaModal isOpen={isOpen} onRequestClose={() => setOpen(false)} />
    </>
  )
}
