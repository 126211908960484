import React from 'react'
import styled from 'styled-components/macro'
import CheckSvg from '../images/icon/check.svg'
import { Icon } from './Icon/Icon'
import { iconSize } from '../theme/icon'
import { sizes } from '../theme/sizes'
import rem from 'polished/lib/helpers/rem'
import { color } from '../theme/color'
import {
  VerticalMarginProps,
  verticalMarginsCss,
} from '../theme/mixins/margins'

const ListRaw: React.FC<VerticalMarginProps & { className?: string }> = ({
  children,
  className,
}) => <ul className={className}>{children}</ul>

const ListRawStyled = styled(ListRaw)`
  ${verticalMarginsCss};
`

const ListItemRaw: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <ListItemWrapper className={className}>
    <ListItemIcon size="medium" component={<CheckSvg />} />
    {children}
  </ListItemWrapper>
)

const ListItem = styled(ListItemRaw)`
  & + & {
    margin-top: 10px;
  }
`

const ListItemIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  path {
    fill: ${color.turquoise};
  }
`

const ListItemWrapper = styled.li`
  position: relative;
  text-align: left;
  padding-left: ${rem(iconSize.medium + sizes.size3)};
`

export const List = Object.assign(ListRawStyled, { Item: ListItem })
