import React from 'react'
import { Container } from '../../../components/grid/Container'
import { Col } from '../../../components/grid/Col'
import { Row } from '../../../components/grid/Row'
import styled, { css } from 'styled-components/macro'
import { color } from '../../../theme/color'
import { rem } from 'polished'
import { sizes } from '../../../theme/sizes'
import { media } from '../../../theme/mixins/media'
import { TarifaUnica } from './TarifaUnica'
import { TarjetaRegalo } from './TarjetaRegalo'
import TarifaBgUrl from '../../../images/tarifa-bg.jpg'
import { grid } from '../../../theme'

export const TarifaSection = () => (
  <Wrapper id="tarifa">
    <Container>
      <Row>
        <Col flex justify="center" tablet={6}>
          <ColumnWrapper>
            <TarifaUnica />
          </ColumnWrapper>
        </Col>

        <Col flex justify="center" tablet={6}>
          <ColumnWrapper>
            <TarjetaRegalo />
          </ColumnWrapper>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  background-image: url(${TarifaBgUrl});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  ${media.onlyMobile`
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 50%;
      left: 0;
      right: 0;
      z-index: 1;
    }

    /** Columna izquierda */
    &::before {
      top: 0;
      opacity: 0.8;
      background-color: ${color.white};
    }

    /** Columna derecha */
    &::after {
      bottom: 0;
      opacity: 0.8;
      background-color: ${color.black};
      /* background-color: ${color.white}; */
    }
  `}

  ${media.tablet`
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 50%;
      top: 0;
      bottom: 0;
      z-index: 1;
    }

    /** Columna izquierda */
    &::before {
      left: 0;
      opacity: 0.8;
      background-color: ${color.white};
    }

    /** Columna derecha */
    &::after {
      right: 0;
      opacity: 0.8;
      background-color: ${color.black};
      /* background-color: ${color.white}; */
    }
  `}
`

const ColumnWrapper = styled.div`
  padding: ${rem(sizes.size7)} ${rem(grid.default.gap)};

  ${media.desktop`
    padding: ${rem(sizes.size9)};
  `}

  text-align: center;
  z-index: 2;
`
