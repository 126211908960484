import React, { useState } from 'react'
import { Text } from '../../../components/Text'
import { Button } from '../../../components/Button/Button'
import { Block } from '../../../components/Block'
import { Icon } from '../../../components/Icon/Icon'
import GiftIconSvg from '../../../images/icon/gift.svg'
import styled from 'styled-components/macro'
import { sizes } from '../../../theme'
import { rem } from 'polished'
import { TarjetaRegaloModal } from './TarjetaRegaloModal'

export const TarjetaRegalo = () => {
  // const [errorMessage, setErrorMessage] = useState(null)

  // const checkout = () => {
  //   setErrorMessage(null)
  //   const stripe = window.Stripe('pk_test_p6JbqbS0QqLHCMrAldgwkJXO004DlbwXlK')
  //   stripe
  //     .redirectToCheckout({
  //       items: [{ sku: 'sku_GRbpi7uYMUb2GA', quantity: 1 }],
  //       successUrl: 'https://centro-feeling.netlify.com/tarjeta-regalo/success',
  //       cancelUrl: 'https://centro-feeling.netlify.com/tarjeta-regalo/canceled',
  //     })
  //     .then(function(result: any) {
  //       if (result.error) {
  //         // If `redirectToCheckout` fails due to a browser or network
  //         // error, display the localized error message to your customer.
  //         setErrorMessage(result.error.message)
  //       }
  //     })
  // }

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <Icon
        size="large"
        component={<GiftIconSvg />}
        color="turquoise"
        marginBottom="size5"
      ></Icon>
      <Text
        color="white"
        size="xlarge"
        weight="light"
        uppercase
        marginBottom="size5"
      >
        Tarjeta regalo
      </Text>
      <Text color="white" size="large" marginBottom="size7">
        Si quieres hacer un regalo especial, disponemos de tarjetas regalo para
        sorprender a quien más quieres.
      </Text>
      {/* <Block textAlign="center">
        {errorMessage && <Error>{errorMessage}</Error>}
        <Button secondary onClick={checkout}>
          ¡Quiero saber más!
        </Button>
      </Block> */}

      <Block textAlign="center">
        <Button secondary onClick={() => setOpen(true)}>
          ¡Quiero saber más!
        </Button>
      </Block>
      <TarjetaRegaloModal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
      />
    </>
  )
}

const Error = styled.div`
  background-color: red;
  color: white;
  padding: 20px;
  margin-bottom: ${rem(sizes.size4)};
`
