import React from 'react'
import ReactModal from 'react-modal'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { sizes, color } from '../../../theme'
import { media } from '../../../theme/mixins/media'
import rem from 'polished/lib/helpers/rem'
import mix from 'polished/lib/color/mix'
import { Text } from '../../../components/Text'
import { Separator } from '../../../components/Separator'
import { Icon } from '../../../components/Icon/Icon'
import CrossSvg from '../../../images/icon/cross.svg'
import { HtmlContent } from '../../../components/HtmlContent/HtmlContent'
import { Block } from '../../../components/Block'
import { Button } from '../../../components/Button/Button'
import { Link } from 'gatsby'
import TarjetaRegaloImg from '../../../images/tarjeta-regalo.jpg'

interface Props {
  isOpen: boolean
  onRequestClose: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void
}

export const TarjetaRegaloModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  return (
    <>
      <ReactModal
        ariaHideApp={false}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
        className="modal"
        overlayClassName="overlay"
      >
        <ModalStyles />
        <CloseButton onClick={onRequestClose}>
          <Icon size="medium" component={<CrossSvg />} color="turquoise" />
        </CloseButton>
        <Text size="h2" weight="light" uppercase center>
          ¿Quieres sorprender?
        </Text>

        <Separator />

        <Text size="h4" marginBottom="size6" center>
          ¡REGALA SALUD & BIENESTAR a los que más quieres!
        </Text>
        <HtmlContent>
          <Text as="div" marginBottom="size2">
            ¿Qué detalle más original y bonito que un tratamiento para mejorar
            su salud?
          </Text>

          <ul>
            <li>
              Sorprende con las tarjetas de regalo que tenemos para ti y los
              tuyos.
            </li>
            <li>Flexible, y válida para todos nuestros tratamientos. </li>
            <li>
              Se adapta a las necesidades de nuestros pacientes y a las de sus
              familiares y amigos.{' '}
            </li>
            <li>
              Eliges cualquier tratamiento que quieras regalar, el número de
              sesiones, abonas el importe, y la persona que recibe el regalo
              solo tiene que pedir cita y presentar la tarjeta para disfrutar de
              nuestros tratamientos.
            </li>
          </ul>

          <Block flex justify="center" marginTop="size6">
            <img src={TarjetaRegaloImg} />
          </Block>

          <Separator />

          <Text as="div" marginBottom="size2">
            Condiciones de compra y uso:
          </Text>
          <ul>
            <li>
              Este vale de sesión de fisioterapia se adquiere de forma física en
              nuestro centro (Pago efectivo/bizum).
            </li>
            <li>
              El vale deberá ser pagado antes de ser entregado a la persona que
              lo adquiere.
            </li>
            <li>
              Al adquirir el vale, debes indicarnos los datos de la persona a la
              que se le regala.
            </li>
            <li>
              Sólo podrán canjearse las tarjetas regalo que estén adquiridas en
              nuestro centro.
            </li>
            <li>
              El vale tendrá validez de uso hasta 3 meses después de su compra.
            </li>
          </ul>
        </HtmlContent>
      </ReactModal>
    </>
  )
}

const ModalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;

    background-color: rgba(0, 0, 0, 0.90);
    z-index: 100;
    overflow-y: auto;
  }

  .ReactModal__Content {
    position: relative;
    padding: ${rem(sizes.size5)};
    margin: 1rem;
    max-width: 60rem;

    background-color: ${color.white};
    border-radius: 24px;
    outline: 0;
    margin: auto;

    ${media.tablet`
      max-width: auto;
      padding: ${rem(sizes.size7)};
    `}
  }
`

const CloseButton = styled.div`
  display: inline-flex;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 10px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    path {
      fill: ${mix(0.2, color.black, color.turquoise)};
    }
  }

  ${media.tablet`
    right: 20px;
    top: 20px;
  `}
`
